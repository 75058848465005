import { createStore } from 'vuex';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, onSnapshot, doc, getDoc, setDoc, orderBy } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns'
//import { format } from 'date-fns'
import { de } from 'date-fns/locale'

export default createStore({
  state: {
    User: null,
    Version: 'β 0.5.6',
    VersionVon: '05. Oktober 2024',

    LoginText: '',
    DicerText: '',
    DicerTotal: '',
    snackbarText: '',
    activeCharID: '',
    activeChar: null,
    activeKampagneID: '',
    activeKampagne: null,

    Arten: ['NPC+', 'NPC-', 'Kontakt', 'Gruppe', 'Ort', 'Kampage', 'Szene'],
    systeme: ['DSA 4.1', 'D&D 5e', 'Shadowrun 5e'],

    Einstellungen: null,

    Nachrichten: [],
  },

  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    },

    callSnackbar(state, value) {
      state.snackbarText = value
      state.showSnackbar = true
    },

    callDicer(state, payload) {
      if (!state.Einstellungen || state.Einstellungen.popupW) {
        state.DicerText = payload.result.replace("wirft: ", "").replace("wirft ", "")
        state.DicerTotal = payload.total
        state.showDicer = true
      }
    }
  },

  getters: {
    formatierteNachrichten: (state) => {
      return state.Nachrichten.map(nachricht => {
        if (nachricht.CreatedAt && nachricht.CreatedAt.toDate) {
          const datum = nachricht.CreatedAt.toDate()
          const formatiertesDatum = "(vor " + formatDistanceToNow(datum, { locale: de }) + ")"
          //    const formatiertesDatum = format(datum, 'dd.MM.yyyy HH:mm:ss', { locale: de })
          return { ...nachricht, formatiertesDatum }
        } else {
          return { ...nachricht, formatiertesDatum: 'n.a.' }
        }
      })
    }
  },

  actions: {
    async startUp() {
      await this.dispatch('initAuth')
      console.log("Initialisierung der Athentifizierung gestartet...")
      await this.dispatch('ensureSettingsDocumentExists')
      console.log("Initialisierung der Einstellungsdokumente abgeschlossen.")
      await this.dispatch('initEinstellungen')
      console.log("Initialisierung der Einstellungen abgeschlossen")
      await this.dispatch('initMessages')
      console.log("Initialisierung der Nachrichten abgeschlossen.")
      console.log("Startup Prozess abgeschlossen.")
    },

    initAuth({ commit }) {
      return new Promise((resolve, reject) => {
        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {
          if (user === null) {
            commit('setState', { key: 'showCharaktere', value: false })
            commit('setState', { key: 'showEinstellungen', value: false })
            commit('setState', { key: 'LoginText', value: 'Nicht eingeloggt.' })
            commit('setState', { key: 'User', value: null })
          } else if (user.emailVerified) {
            commit('setState', { key: 'LoginText', value: user.displayName + ' du bist eingeloggt.' })
            commit('setState', { key: 'User', value: user })
          } else {
            commit('setState', { key: 'LoginText', value: user.displayName + ' deine E-Mail Adresse muss noch verifiziert werden.' })
            commit('setState', { key: 'User', value: user })
          }
          resolve()
        }, reject)
      })
    },

    async ensureSettingsDocumentExists({ state }) {
      if (state.User) {
        const db = getFirestore();
        const userUid = state.User.uid
        const settingsDocRef = doc(db, "Einstellungen", userUid)
        try {
          const docSnap = await getDoc(settingsDocRef)
          if (!docSnap.exists()) {
            await setDoc(settingsDocRef, {
              User: userUid,
              discordWebhookURL: '',
              useDiscordWebhook: false,
              sessionId: userUid,
              popupW: true,
              popupDuration: 2000,
            });
          }
        } catch (error) {
          console.error("Fehler beim Überprüfen/Erstellen des Einstellungen-Dokuments:", error)
        }
      }
    },

    async initEinstellungen({ commit, state }) {
      const db = getFirestore();
      if (!state.User || !state.User.uid) {
        console.log("Kein Benutzer UID gefunden.")
        return;
      }
      const userUid = state.User.uid
      const ColRefEinst = collection(db, 'Einstellungen');
      const q = query(ColRefEinst, where("User", "==", userUid))
      try {
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data()
          commit('setState', { key: 'Einstellungen', value: docData })
        } else {
          console.log("Keine Einstellungen gefunden.")
        }
      } catch (error) {
        console.error("Fehler beim Laden der Einstellungen:", error)
      }
    },

    async initMessages({ commit }) {
      if (this.state.User) {
        const db = getFirestore()
        const q = query(
          collection(db, "Nachrichten"),
          where("sessionId", "==", this.state.Einstellungen.sessionId),
          orderBy("CreatedAt", "asc")
        )
        onSnapshot(q, (querySnapshot) => {
          this.Nachrichten = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          commit('setState', { key: 'Nachrichten', value: this.Nachrichten })
        });
      } else {
        console.log("In der Historie kein User gefunden.")
      }
    },

  }
})